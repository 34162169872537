<template>
  <div class="flex min-h-screen overflow-hidden surface-0 align-items-center justify-content-center min-w-screen">
    <div class="grid p-2 justify-content-center lg:p-0" style="min-width: 80%">
      <div class="col-12 xl:col-6 tw-shadow-xl tw-border-2 tw-border-gray-200 tw-rounded-2xl">
        <div class="w-full h-full px-4 m-0 py-7 md:w-10 mx-auto">
          <router-link :to="{name: 'forgot-password'}" class="block font-bold no-underline ml-2 mb-3 cursor-pointer text-primary tw-text-xl">
            <i class="pi pi-arrow-left mr-2"></i>
            Cancel
          </router-link>
          <div class="mt-5 text-center col-12 xl:mt-0">
            <router-link :to="{ name: 'landing' }">
              <img
                src="~@/assets/images/logo.png"
                alt="Sakai logo"
                class="mx-auto"
                style="height: 60px"
              />
            </router-link>
          </div>

          <div class="text-center tw-mb-8 tw-mt-10">
            <h1 class="tw-text-4xl tw-font-bold">Reset Password</h1>
          </div>

          <form @submit.prevent="doReset()" class="w-full mx-auto">
            <label for="email1" class="block mb-2 text-xl font-medium text-900">New Password</label>
            <Password
              v-model="form.newPassword"
              placeholder="Password"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              :feedback="false"
              inputStyle="padding:1rem"
              :disabled="form.is_submit">
            </Password>

            <label for="email1" class="block mb-2 text-xl font-medium text-900">Confirm Password</label>
            <Password
              v-model="form.confirmPassword"
              placeholder="Password"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              :feedback="false"
              inputStyle="padding:1rem"
              :disabled="form.is_submit">
            </Password>

            <Button type="submit"
                class="p-button p-component w-full p-3 text-xl tw-mt-12"
                :disabled="form.is_submit">
                <span v-if="!form.is_submit" class="p-button-label">Reset Password</span>
                <i class="pi pi-spin pi-spinner p-button-label tw-text-xl" v-else></i>
            </Button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: ['token'],
  data() {
    return {
      form: {
        newPassword: '',
        confirmPassword: '',
        token: this.token,
        is_submit: false,
      },
    }
  },
  methods: {
    doReset() {
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error!',
          detail: 'Password not match',
          life: 3000,
        })
        return
      }
      this.form.is_submit = true
      this.$http
        .post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/reset-password`, this.form)
        .then(response => {
          this.form.is_submit = false
          if (response.data.code !== 200) return

          this.$toast.add({
            severity: 'success',
            summary: 'Success!',
            detail: 'Password has been changed successfully, please login again.',
            life: 3000,
          })

          this.$router.push({name: 'login'})
        }).catch(error => {
          this.form.is_submit = false
          this.$toast.add({
            severity: 'error',
            summary: 'Error!',
            detail: error.response.data.message,
            life: 3000,
          })
        })
    },
  },
};
</script>

<style scoped></style>
